<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0"
        :span="24"
        class="organizetoolbar"
        style="padding-bottom: 0px"
      >
        <el-form
          :inline="true"
          ref="selectForm"
          :model="selectForm"
          class="select_form"
        >
          <el-form-item label="用户名:">
            <el-input
              v-model="selectForm.userName"
              placeholder="用户名"
            ></el-input>
          </el-form-item>
          <el-form-item label="证书系列:">
            <el-input
              v-model="selectForm.certType"
              placeholder="证书系列"
            ></el-input>
          </el-form-item>
          <el-form-item label="证书类别:">
            <el-input
              v-model="selectForm.certCategory"
              placeholder="证书系列"
            ></el-input>
          </el-form-item>
          <el-form-item label="证书专业:">
            <el-input
              v-model="selectForm.certProfession"
              placeholder="证书专业"
            ></el-input>
          </el-form-item>
          <el-form-item label="证书名称:">
            <el-input
              v-model="selectForm.certName"
              placeholder="证书名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="状态:">
            <el-select
              v-model="selectForm.userState"
              clearable
              placeholder="请选择"
              style="“width: 100%”"
            >
              <el-option label="在职" value="在职"></el-option>
              <el-option label="离职" value="离职"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公司:">
            <el-input
              v-model="selectForm.factory"
              placeholder="公司名称"
            ></el-input>
          </el-form-item>
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
          ></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="calc-height">
      <el-table
        :data="users"
        highlight-current-row
        height="100%"
        @current-change="selectCurrentRow"
        @row-dblclick="handleShow"
        class="new_table"
      >
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="UserName" label="姓名"> </el-table-column>
         <el-table-column prop="Factory" label="公司"> </el-table-column>
          <el-table-column prop="DptName" show-overflow-tooltip label="部门"> </el-table-column>
           <el-table-column prop="PostName" show-overflow-tooltip label="岗位"> </el-table-column>
        <el-table-column prop="UserState" label="人员状态"> </el-table-column>
        <el-table-column prop="CertType" show-overflow-tooltip label="证书系列"> </el-table-column>
        <el-table-column label="证书名称" show-overflow-tooltip prop="CertName"></el-table-column>
        <el-table-column label="证书类别" prop="CertCategory"></el-table-column>
        <el-table-column label="专业" prop="CertProfession"></el-table-column>
        <el-table-column label="证书状态" prop="CertState"></el-table-column>
        <el-table-column label="补贴金额" prop="SubsidyMoney"></el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper,total"
      :page-count="total"
      :total="totaldata"
    >
    </el-pagination>

    <el-dialog
      :title="operation ? '新增' : '编辑'"
      :visible.sync="SakaryVisible"
      v-model="SakaryVisible"
      :close-on-click-modal="false"
    >
        <el-form :rules="SakaryRules"
                 ref="SakaryForm"
                 :model="SakaryForm"
                 label-width="100px">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="姓名:" prop="UserName">
                        <el-input type="text"
                                  :disabled="true"
                                  v-model="SakaryForm.UserName">
                            <el-button slot="append"
                                       icon="el-icon-search"
                                       @click="chooseUser"></el-button>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="身份证号:" prop="IDCard">
                        <div>{{SakaryForm.IDCard}}</div>
                        <!-- <el-input
                  type="text"
                  v-model="SakaryForm.IDCard"
                  :disabled="true"
                >
                </el-input> -->
                    </el-form-item>
                </el-col>
            </el-row>
            
            <el-row>
                <el-col :span="12">
                    <el-form-item label="证书名称:" prop="CertName">
                        <el-input type="text" v-model="SakaryForm.CertName"> </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="证书系列:" prop="CertType">
                        <el-select v-model="SakaryForm.CertType"
                                   filterable
                                   placeholder="证书系列"
                                   @change="CertTypeModel($event)"
                                   style="width: 100%">
                            <el-option v-for="item in CertTypeLists"
                                       :key="item.Id"
                                       :label="item.Name"
                                       :value="item.Name"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="类别:">
                        <el-select v-model="SakaryForm.CertCategory"
                                   filterable
                                   placeholder="证书系列"
                                   @change="CertCategoryModel($event)"
                                   style="width: 100%">
                            <el-option v-for="item in CertCategoryLists"
                                       :key="item.Id"
                                       :label="item.Name"
                                       :value="item.Name"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="专业:">
                        <el-select v-model="SakaryForm.CertProfession"
                                   filterable
                                   placeholder="证书系列"
                                   @change="CertProfessionModel($event)"
                                   style="width: 100%">
                            <el-option v-for="item in CertProfessionLists"
                                       :key="item.Id"
                                       :label="item.Name"
                                       :value="item.Name"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="证书编号:">
                        <el-input type="text" v-model="SakaryForm.CertCode"> </el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="注册编号:" prop="RegisterCode">
                        <el-input type="text" v-model="SakaryForm.RegisterCode">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="管理号:" prop="ManageCode">
                        <el-input type="text" v-model="SakaryForm.ManageCode"> </el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="发证单位:" prop="CertAssUnit">
                        <el-input type="text" v-model="SakaryForm.CertAssUnit">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="评定单位:" prop="CertFairUnit">
                        <el-input type="text" v-model="SakaryForm.CertFairUnit">
                        </el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="签发时间:" prop="CertFairDate">
                        <el-date-picker type="date"
                                        v-model="SakaryForm.CertFairDate"
                                        value-format="yyyy-MM-dd"
                                        placeholder="选择日期"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="变更日期:" prop="ChangeDate">
                        <el-date-picker type="date"
                                        v-model="SakaryForm.ChangeDate"
                                        value-format="yyyy-MM-dd"
                                        placeholder="选择日期"></el-date-picker>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="有效期限:" prop="CertEndDate">
                        <el-date-picker type="date"
                                        v-model="SakaryForm.CertEndDate"
                                        value-format="yyyy-MM-dd"
                                        placeholder="选择日期"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="存档日期:" prop="CertSaveDate">
                        <el-date-picker type="date"
                                        v-model="SakaryForm.CertSaveDate"
                                        value-format="yyyy-MM-dd"
                                        placeholder="选择日期"></el-date-picker>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="补贴日期:" prop="SubsidyDate">
                        <el-date-picker type="date"
                                        v-model="SakaryForm.SubsidyDate"
                                        value-format="yyyy-MM-dd"
                                        placeholder="选择日期"></el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="补贴类型:" prop="SubsidiesType">
                        <el-select v-model="SakaryForm.SubsidiesType" placeholder="选择" style="width: 100%">
                            <el-option label="证书补贴" value="证书补贴"></el-option>
                            <el-option label="技能津贴" value="技能津贴"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="补贴金额:" prop="SubsidyMoney">
                        <el-input type="money" v-model="SakaryForm.SubsidyMoney">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="12">
                    <el-form-item label="复审周期:" prop="CertCycle">
                        <el-select v-model="SakaryForm.CertCycle"
                                   filterable
                                   placeholder="复审周期"
                                   style="width: 100%">
                            <el-option label="0" value="0"></el-option>
                            <el-option label="1" value="1"></el-option>
                            <el-option label="2" value="2"></el-option>
                            <el-option label="3" value="3"></el-option>
                            <el-option label="4" value="4"></el-option>
                            <el-option label="5" value="5"></el-option>
                            <el-option label="6" value="6"></el-option>
                            <el-option label="7" value="7"></el-option>
                            <el-option label="8" value="8"></el-option>
                            <el-option label="9" value="9"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="发放周期:" prop="CerFFTime">
                        <el-select v-model="SakaryForm.CerFFTime"
                                   placeholder="发放周期"
                                   style="width: 100%">
                            <el-option label="无" value="无"></el-option>
                            <el-option label="半年度" value="半年度"></el-option>
                            <el-option label="季度" value="季度"></el-option>
                            <el-option label="月度" value="月度"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="发放方式:" prop="CerFFType">
                        <el-select v-model="SakaryForm.CerFFType"
                                   placeholder="发放方式"
                                   style="width: 100%">
                            <el-option label="无" value="无"></el-option>
                            <el-option label="集团发放" value="集团发放"></el-option>
                            <el-option label="工资发放" value="工资发放"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="发放公司:" prop="PaymentAccount">
                        <el-select v-model="SakaryForm.PaymentAccount"
                                   placeholder="发放公司"
                                   style="width: 100%">
                            <el-option label="无" value="无"></el-option>
                            <el-option label="格瑞德集团" value="格瑞德集团"></el-option>
                            <el-option label="人工环境设计研究院" value="人工环境设计研究院"></el-option>
                            <el-option label="冷却设备有限公司" value="冷却设备有限公司"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="获取方式:" prop="CertGetType">
                        <el-select v-model="SakaryForm.CertGetType"
                                   placeholder="获取方式"
                                   style="width: 100%">
                            <el-option label="个人" value="个人"></el-option>
                            <el-option label="公司" value="公司"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="证书状态:" prop="CertState">
                        <el-select v-model="SakaryForm.CertState"
                                   filterable
                                   placeholder="证书状态"
                                   style="width: 100%">
                            <el-option label="在职未过期" value="在职未过期"></el-option>
                            <el-option label="在职已过期" value="在职已过期"></el-option>
                            <el-option label="离职未过期" value="离职未过期"></el-option>
                            <el-option label="离职已过期" value="离职已过期"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="存放地点:" prop="SavePlace">
                        <el-select v-model="SakaryForm.SavePlace"
                                   filterable
                                   placeholder="存放地点"
                                   @change="SavePlaceModel($event)"
                                   style="width: 100%">
                            <el-option v-for="item in SavePlaceLists"
                                       :key="item.Id"
                                       :label="item.Name"
                                       :value="item.Name"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="使用有效期:" prop="ValidityDate">
                        <el-date-picker type="date"
                                        v-model="SakaryForm.ValidityDate"
                                        value-format="yyyy-MM-dd"
                                        placeholder="选择日期"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="存放状态变更说明(借阅记录):"
                                  prop="CertSaveState"
                                  label-width="200px">
                        <el-select v-model="SakaryForm.CertSaveState" placeholder="选择">
                            <el-option label="已借用" value="已借用"></el-option>
                            <el-option label="未借用" value="未借用"></el-option>
                            <el-option label="在职取走" value="在职取走"></el-option>
                            <el-option label="离职带走" value="离职带走"></el-option>
                            <el-option label="离职未带走" value="离职未带走"></el-option>
                            <el-option label="换证复审" value="换证复审"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="社保缴纳公司:" prop="UserFactory">
                        <el-input type="money" v-model="SakaryForm.UserFactory">
                        </el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <label for="Summarize" class="el-form-item__label">备注:</label>

                    <el-input type="textarea"
                              v-model="SakaryForm.Remark"
                              style="width: 90%"></el-input>
                </el-col>
            </el-row>
            <upload-files :files="SakaryForm.FileArry"
                          :key="key"
                          action="/cyl/ftp/ftp/upload"
                          :limit="20"
                          @fun="dealFiles"
                          :IsDisabled="operation1"
                          :IsDel="operation1"></upload-files>
            <el-row>
                <el-col :span="24">
                    <label>承诺书:</label>
                </el-col>
            </el-row>
            <upload-files :files="SakaryForm.CommitmentFileArry"
                          :key="keyCommitment"
                          action="/cyl/ftp/ftp/upload"
                          :limit="20"
                          @fun="dealFilesCommitment"
                          :IsDisabled="operation1"
                          :IsDel="operation1"></upload-files>
            <el-row v-if="operation1">
                <el-col :span="24" style="text-align: center">
                    <el-button type="primary" v-on:click="onSubmit('SakaryForm')">提交</el-button>
                </el-col>
            </el-row>
        </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="addUserVisible"
      v-model="addUserVisible"
      width="75%"
      center
      append-to-body
    >
      <UserChoose
        :data="choosedusers"
        :all="true"
        :single="false"
        @callback="usercallFunction"
      ></UserChoose>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import UploadFiles from "../../components/UploadFiles";
import {
  GetCertificatePageList,
  GetCertificateByType,
  UpdateCertificate,
  AddCertificate,
  GetCertificateDetail,
  GetInsurancePersonUserID,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import UserChoose from "../../components/UserChoose";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar, UserChoose, UploadFiles },
  data() {
    return {
      loading: true,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      operation: false, // true:新增, false:编辑
      operation1: true,
      pageSize: 20, // 每页的数据条数
      page: 1,
        key: 0,
        keyCommitment:0,
      searchVal: "",
      CertTypeLists: [],
      CertCategoryLists: [],
      CertProfessionLists: [],
      SavePlaceLists: [],
      choosedusers: [],
      SakaryVisible: false,
      addUserVisible: false,
      selectForm: {
        certType: null,
        certCategory: null,
        certProfession: null,
        certName: null,
        certType: null,
        userName: null,
        userState: null,
        factory:null,
      },
      SakaryForm: {
        UserName: "",
        UserId: "",
        CertTypeId: "",
        CertName: "",
        CertCategoryId: "",
        CertType: "",
        CertCategory: "",
        CertProfessionId: "",
        CertProfession: "",
        CertCode: "",
        ManageCode: "",
        RegisterCode: "",
        CertAssUnit: "",
        CertFairUnit: "",
        ChangeDate: "",
        CertFairDate: "",
        CertEndDate: "",
        CertSaveDate: "",
        SubsidyMoney: "",
        SubsidyDate: "",
        CertCycle: "0",
        CerFFTime: "季度",
        CerFFType: "集团发放",
        CertGetType: "个人",
        CertState: "在职未过期",
        SavePlaceId: "",
        SavePlace: "",
          CertSaveState: "",
          SubsidiesType:"",
        Remark: "",
          FileArry: [],
          CommitmentFileArry:[],
          UserFactory:"",
      },
      users: [],
      buttonList: [],
      SakaryRules: {
        UserName: [{ required: true, message: "请输入名称", trigger: "blur" }],
        CertType: [
          { required: true, message: "请选择证书系列", trigger: "blur" },
        ],
        CertCategory: [
          { required: true, message: "请选择类别", trigger: "blur" },
        ],
        CertProfession: [
          { required: true, message: "请选择专业", trigger: "blur" },
        ],
        CertCode: [
          { required: true, message: "请输入证书编号", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      console.log(row);
      GetCertificateByType({ type: "证书系列" }).then((res) => {
        console.log(res);
        this.CertTypeLists = res.data.response;
      });
      GetCertificateByType({ type: "证书类别" }).then((res) => {
        this.CertCategoryLists = res.data.response;
      });
      GetCertificateByType({ type: "证书专业" }).then((res) => {
        this.CertProfessionLists = res.data.response;
      });
      GetCertificateByType({ type: "存放地点" }).then((res) => {
        this.SavePlaceLists = res.data.response;
      });
      GetCertificateDetail({ id: row.Id }).then((res) => {
        console.log(res);
        
        this.SakaryForm = res.data.response;

        if(res.data.response.UserFactory==""||res.data.response.UserFactory==undefined||res.data.response.UserFactory==null){
          GetInsurancePersonUserID({ userId: res.data.response.UserId }).then((res) => {
            console.log(res.data.response.PaymentAccount)
            if(res.data.response.PaymentAccount==""||res.data.response.PaymentAccount==undefined||res.data.response.PaymentAccount==null){

            }else{
              this.SakaryForm.UserFactory = res.data.response.PaymentAccount;
            }
          });
        }

        this.key += 1;
        this.keyCommitment += 1;
        this.SakaryVisible = true;
      });

      this.operation = false;
      this.operation1 = true;
    },
    handleShow() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      GetCertificateByType({ type: "证书系列" }).then((res) => {
        console.log(res);
        this.CertTypeLists = res.data.response;
      });
      GetCertificateByType({ type: "证书类别" }).then((res) => {
        this.CertCategoryLists = res.data.response;
      });
      GetCertificateByType({ type: "证书专业" }).then((res) => {
        this.CertProfessionLists = res.data.response;
      });
      GetCertificateByType({ type: "存放地点" }).then((res) => {
        this.SavePlaceLists = res.data.response;
      });
      GetCertificateDetail({ id: row.Id }).then((res) => {
        console.log(res.data.response.UserId);
        
        this.SakaryForm = res.data.response;

        if(res.data.response.UserFactory==""||res.data.response.UserFactory==undefined||res.data.response.UserFactory==null){
          GetInsurancePersonUserID({ userId: res.data.response.UserId }).then((res) => {
            console.log(res.data.response.PaymentAccount)
            if(res.data.response.PaymentAccount==""||res.data.response.PaymentAccount==undefined||res.data.response.PaymentAccount==null){

            }else{
              this.SakaryForm.UserFactory = res.data.response.PaymentAccount;
            }
          });
        }


          this.key += 1;
          this.keyCommitment += 1;
        this.SakaryVisible = true;
      });

      this.operation = false;
      this.operation1 = false;
    },
    handleAdd() {
      (this.SakaryForm = {
        UserName: "",
        UserId: "",
        CertTypeId: "",
        CertName: "",
        CertCategoryId: "",
        CertType: "",
        CertCategory: "",
        CertProfessionId: "",
        CertProfession: "",
        CertCode: "",
        ManageCode: "",
        RegisterCode: "",
        CertAssUnit: "",
        CertFairUnit: "",
        ChangeDate: "",
        CertFairDate: "",
        CertEndDate: "",
        CertSaveDate: "",
        SubsidyMoney: "",
        SubsidyDate: "",
        CertCycle: "0",
        CerFFTime: "季度",
        CerFFType: "集团发放",
        CertGetType: "个人",
        CertState: "在职未过期",
        SavePlaceId: "",
        SavePlace: "",
          CertSaveState: "",
          SubsidiesType:"",
        Remark: "",
          FileArry: [],
          CommitmentFileArry:[],
          UserFactory:""
      }),
        GetCertificateByType({ type: "证书系列" }).then((res) => {
          console.log(res);
          this.CertTypeLists = res.data.response;
        });
      GetCertificateByType({ type: "证书类别" }).then((res) => {
        this.CertCategoryLists = res.data.response;
      });
      GetCertificateByType({ type: "证书专业" }).then((res) => {
        this.CertProfessionLists = res.data.response;
      });
      GetCertificateByType({ type: "存放地点" }).then((res) => {
        this.SavePlaceLists = res.data.response;
      });
        this.key += 1;
        this.keyCommitment += 1;
      (this.operation = true), (this.SakaryVisible = true);
      this.operation1 = true;
    },
    usercallFunction(newdata) {
      if (newdata.length > 1) {
        this.$message({
          message: "不允许选择多人",
          type: "error",
        });
        return;
      }
      
      this.$api.GetCurrentUserInfo({ userId: newdata[0].Id }).then((res) => {
         console.log(res.data)
         this.SakaryForm.IDCard = res.data.response.IDCard;
          this.SakaryForm.UserId = newdata[0].Id;
          this.SakaryForm.UserName = newdata[0].UserNameClaim;

        GetInsurancePersonUserID({ userId: newdata[0].Id }).then((res) => {
            console.log(res.data.response.PaymentAccount)
            if(res.data.response.PaymentAccount==""||res.data.response.PaymentAccount==undefined||res.data.response.PaymentAccount==null){

            }else{
              this.SakaryForm.UserFactory = res.data.response.PaymentAccount;
            }
          });

          this.addUserVisible = false;
      });

    
    },
    chooseUser() {
      this.addUserVisible = true;
    },
    //获取用户列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }

      let para = {
        page: this.page,
        size: this.pageSize,
        certType: this.selectForm.certType,
        certCategory: this.selectForm.certCategory,
        certProfession: this.selectForm.certProfession,
        certName: this.selectForm.certName,
        certType: this.selectForm.certType,
        userName: this.selectForm.userName,
        userState: this.selectForm.userState,
        factory:this.selectForm.factory,
      };
      GetCertificatePageList(para).then((res) => {
        console.log(res);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    CertTypeModel(id) {
      let obj = {};
      obj = this.CertTypeLists.find((item) => {
        //model就是上面的数据源

        return item.Name === id; //筛选出匹配数据
      });
      this.SakaryForm.CertTypeId = obj.Id;
    },
    CertCategoryModel(id) {
      let obj = {};
      obj = this.CertCategoryLists.find((item) => {
        //model就是上面的数据源

        return item.Name === id; //筛选出匹配数据
      });
      this.SakaryForm.CertCategoryId = obj.Id;
    },
    CertProfessionModel(id) {
      let obj = {};
      obj = this.CertProfessionLists.find((item) => {
        //model就是上面的数据源

        return item.Name === id; //筛选出匹配数据
      });
      this.SakaryForm.CertProfessionId = obj.Id;
    },
    SavePlaceModel(id) {
      let obj = {};
      obj = this.SavePlaceLists.find((item) => {
        //model就是上面的数据源

        return item.Name === id; //筛选出匹配数据
      });
      this.SakaryForm.SavePlaceId = obj.Id;
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let para = Object.assign({}, this.SakaryForm);
            console.log(para);
            if (this.operation) {
              //新增
              AddCertificate(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: "添加成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible = false;
                  this.getData();
                } else {
                  this.$message({
                    message: "添加失败",
                    type: "error",
                    duration: 5000,
                  });
                }
              });
            } else {
              //修改
              UpdateCertificate(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: "修改成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible = false;
                  this.getData(false);
                } else {
                  this.$message({
                    message: "修改失败",
                    type: "error",
                    duration: 5000,
                  });
                }
              });
            }
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
            return false;
          }
        });
      });
    },
    dealFiles(data) {
        console.log(data.backData);
        this.SakaryForm.FileArry = data.backData;
    },
    dealFilesCommitment(data) {
        this.SakaryForm.CommitmentFileArry = data.backData;
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.calc-height {
  height: calc(100vh - 300px) !important;
}
</style>

